import {
	CONNECTED,
	CONNECTING,
	CONNECTION_FAILED,
	DISCONNECTED,
	DISCONNECTING,
	INITIALIZATION_FAILED,
	INITIALIZING,
	RECONNECTING,
	desktopNotification,
	playAudio,
	toggleState
} from "./comms";

import { Client as ConversationsClient } from "@twilio/conversations";
import axios from "axios";
import { isEmpty } from "lodash";
import router from "../router";
import store from "../store";
import i18n from "../i18n";
import { getAuthType } from "./mail";
import { PHONE_CALL_STATUS } from "@/common/const";

export const TWILIO_CONVERSATIONS_MAX = 1000;

let conversationsClient = null;

let conversationEvents = false;

const useConversation = () => {
	const authenticate = async (userGroupId) => {
		console.debug(`Authenticating Twilio client for group [${userGroupId}]`);

		const { data: token } = await axios.post("conversations/tokens", {
			userGroup: userGroupId
		});

		if (token?.migrating) {
			setTimeout(async () => authenticate(userGroupId), 3000);
		} else {
			await conversationTokenAquired(token);
		}
	};

	const refreshToken = async () => {
		const { data: token } = await axios.post("conversations/tokens", {
			userGroup: store.getters["conversation/currentParticipant"]?.identity
		});

		conversationsClient.updateToken(token.jwt);
	};

	const onConversationUpdated = (updatePayload) => {
		const { updateReasons, conversation } = updatePayload;

		console.debug("onConversationUpdated ", conversation.sid);
		const existing = store.getters["conversation/allConversations"]?.[conversation.sid];

		if (existing) {
			store.commit("conversation/updateConversation", { conversation, updateReasons });
		} else {
			store.commit("conversation/addConversation", conversation);
		}

		if ((!existing && conversation.lastMessage?.index >= 0 ) ||
			(
				updateReasons.includes("attributes") &&
				conversation.attributes.hasNotification &&
				!conversation.attributes.hasIncompleteFollowup
			)
		) {
			playAudio();
			desktopNotification();
		}
	};

	const onConversationLeft = (conversation) => {
		console.debug("onConversationLeft ", conversation.sid);
		store.commit("conversation/removeConversation", conversation);
	};

	const onConversationJoined = async (conversation) => {
		console.debug("onConversationJoined", conversation.sid);

		if (
			store.commit("conversation/addConversation", conversation) && // return false if already inserted
			conversation.lastMessage?.index >= 0
		) {
			playAudio();
			desktopNotification();
		}
	};

	const messageAdded = (message) => {
		if (message.attributes.isFlowMessage) {
			console.debug("messageAdded: Flow message");
			return;
		}

		const isCallCompleted = message.state.attributes.status === PHONE_CALL_STATUS.COMPLETED;
		const isPhoneCallMessage =
				message.state?.attributes?.isCall && message.state?.attributes?.direction === "inbound";
		const isEmailMessage = message.state.attributes?.type === "email" && message.state.attributes?.fromPatient;
		const isTextMessage = message.state?.type === "text" && isEmpty(message.state.attributes);

		if (
			message.state.author !== store.getters["conversation/currentParticipant"].identity &&
			message.conversation.sid === store.getters["conversation/singleConversation"]?.sid
		) {
			if (isEmailMessage || (isPhoneCallMessage && !isCallCompleted) || isTextMessage ) {
				playAudio();
				desktopNotification();
			}
		}

		if (
			router.currentRoute.name == "conversations" &&
			message.conversation.sid === store.getters["conversation/singleConversation"].sid
		) {
			store.commit("conversation/addSingleMessage", message);
			store.commit("conversation/setNewMessage", true);
		} else {
			if (message.state.author === store.getters["conversation/currentParticipant"].identity) {
				console.warn("messageAdded: Author is same as participant");
				return;
			}

			if (isEmailMessage || (isPhoneCallMessage && !isCallCompleted) || isTextMessage ) {
				playAudio();
				desktopNotification();
			}

		}

		console.debug("messageAdded");
	};

	const loadExistingConversations = async () => {
		if (store.getters["conversation/loading"]) {
			console.debug("loadAllConversations is already running");
			return;
		}

		if (conversationsClient.connectionState !== CONNECTED) {
			throw new Error("Conversations are not connected yet");
		}

		try {
			// Make sure no conversations events are set
			removeConversationEvents();

			store.commit("conversation/setLoadingAllConversations", true);

			const start = new Date().getTime();

			console.debug("loadAllConversations ...");

			let all = {};

			let actives = [];

			let inactives = [];

			// Load first page
			let pager = await conversationsClient.getSubscribedConversations({
				limit: TWILIO_CONVERSATIONS_MAX
			});

			pager.items.map(c=> {
				initConversation(c);
				all[c.sid] = c;
				if (c.state.current === "active") {
					actives.push(c.sid);
				} else {
					inactives.push(c.sid);
				}
			});

			const fetchNextPage = async (pager) => {
				// Loop for each 100 convo per page
				if (!pager.hasNextPage) {
					store.commit("conversation/setAllConversation", { all, actives, inactives });
					console.debug(`loadAllConversations took ${new Date().getTime() - start} ms`);

					toggleState(CONNECTED);
					loadConversationEvents();
					return;
				}

				pager = await pager.nextPage();

				pager.items.map(c=> {
					initConversation(c);
					all[c.sid] = c;
					if (c.state.current === "active") {
						actives.push(c.sid);
					} else {
						inactives.push(c.sid);
					}
				});

				fetchNextPage(pager);
			};

			// Load subsequent pages and recurse
			fetchNextPage(pager);
		} catch (err) {
			console.error(`Exceptions occured in loadExistingConversations. ${err}`);
			throw err;
		} finally {
			store.commit("conversation/setLoadingAllConversations", false);
		}
	};

	const initConversation = (conversation) => {
		conversation.channelState.lastMessage = conversation.lastMessage || {
			dateCreated: null,
			index: undefined
		};
	};

	const connectionError = async (error) => {
		console.error("connectionError:", error);
		if (!error.terminal) return;
		toggleState(CONNECTION_FAILED);
	};

	const connectionStateChanged = async (connectionState) => {
		console.debug("connectionState:", connectionState);

		switch (connectionState) {
			case CONNECTING:
				toggleState(CONNECTING);
				break;
			case CONNECTED:
				try {
					// Load conversations asynchronously (no await)
					loadExistingConversations();
				} catch (err) {
					toggleState(INITIALIZATION_FAILED);
				}
				break;
			case DISCONNECTING:
			// Prevents "Connexion interompue" when switching groups or updating patients
			case RECONNECTING:
				toggleState(RECONNECTING);
				break;
			case DISCONNECTED:
				if (store.getters["conversation/connectionState"] !== RECONNECTING) {
					toggleState(DISCONNECTED);
				}
				break;
		}
	};

	const removeConversationEvents = () => {
		if (!conversationEvents) {
			return;
		}

		try {
			conversationsClient.removeListener("tokenAboutToExpire", refreshToken);
			conversationsClient.removeListener("tokenExpired", refreshToken);
			conversationsClient.removeListener("conversationJoined", onConversationJoined);
			conversationsClient.removeListener("conversationUpdated", onConversationUpdated);
			conversationsClient.removeListener("conversationLeft", onConversationLeft);
			conversationsClient.removeListener("messageAdded", messageAdded);
			conversationEvents = false;
		} catch (err) {
			console.error("Error while removing conversation events ", err);
			conversationEvents = true;
			throw err;
		}
	};

	const loadConversationEvents = () => {
		if (conversationEvents) {
			return;
		}

		try {
			conversationsClient.on("conversationJoined", onConversationJoined);
			conversationsClient.on("conversationUpdated", onConversationUpdated);
			conversationsClient.on("conversationLeft", onConversationLeft);

			conversationsClient.on("messageAdded", messageAdded);
			conversationsClient.on("tokenAboutToExpire", refreshToken);
			conversationsClient.on("tokenExpired", refreshToken);
			conversationEvents = true;
		} catch (err) {
			console.error("Error while adding conversation events ", err);
			conversationEvents = false;
			throw err;
		}
	};

	const getEmailAuthType = async (email) => {
		if (email) {
			try {
				return await getAuthType(email);
			} catch (error) {
				console.error("Error while getting email auth type", error);
			}
		}
	};

	const conversationTokenAquired = async(conversationToken) => {
		console.debug("Conversation token:", conversationToken);

		if (conversationToken.identity) {
			if (conversationToken.email) {
				try {

					const authType = await getEmailAuthType(conversationToken.email);

					conversationToken = {
						...conversationToken,
						emailProvider: authType?.type,
						valid: authType?.valid
					};
				} catch (error) {
					console.error("Error while getting email token", error);
				}
			}

			store.commit("conversation/setCurrentParticipant", conversationToken);
		}

		initialize(conversationToken);
	};

	const bootstrap = async (userGroupIdentity) => {
		try {
			await initialize();
			await authenticate(userGroupIdentity);
		} catch (err) {
			console.error("bootstrap:", err);
			toggleState(INITIALIZATION_FAILED);
		}
	};

	const initialize = async(conversationToken) => {
		try {
			toggleState(INITIALIZING);
			await cleanup();

			if (conversationToken?.jwt) {
				conversationsClient = await ConversationsClient.create(conversationToken.jwt);
				conversationsClient.on("connectionStateChanged", connectionStateChanged);
				conversationsClient.on("connectionError", connectionError);
			}
		} catch (err) {
			toggleState(INITIALIZATION_FAILED);
			console.error("Conversations initialization error:", err);

			store.commit("alerts/add", {
				type: "error",
				message: i18n.t("error.twilio-client"),
				timeout: true
			});
		}
	};

	const cleanup = async () => {
		store.commit("conversation/destroySingleConversation");

		if (!conversationsClient) return;

		try {
			console.debug("CLEANUP: Removing conversationsClient event hooks");

			removeConversationEvents();

			conversationsClient.removeListener("connectionStateChanged", connectionStateChanged);
			conversationsClient.removeListener("connectionError", connectionError);

			console.debug("CLEANUP: Shutting down conversationsClient");
			await conversationsClient.shutdown();

			console.debug("CLEANUP: Done");
		} catch (err) {
			console.error("Error occured while cleaning up conversation objects", err);
			throw err;
		} finally {
			conversationsClient = null;
			conversationEvents = false;
		}
	};

	return {
		bootstrap,
		toggleState,
		cleanup
	};
};

export default useConversation;
