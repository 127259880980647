<template>
	<v-layout align-center justify-center column class="pb-3">
		<v-card flat>
			<v-card-text>
				<v-text-field
					:value="digitsPressed"
					flat
					solo
					readonly
					class="centered-input text-h6 font-weight-semibold"
				/>
				<div class="mt-n6">
					<v-layout
						v-for="(row, rowIndex) in buttons"
						:key="rowIndex"
						no-gutters
						align-center
						justify-center
					>
						<v-btn
							v-for="(button, buttonIndex) in row"
							:key="buttonIndex"
							color="primary"
							fab
							outlined
							class="ma-2"
							@click="sendDigits(button)"
						>
							<span class="primary--text text-h4 font-weight-bold">
								{{ button }}
							</span>
						</v-btn>
					</v-layout>
				</div>
			</v-card-text>
		</v-card>
	</v-layout>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "Dialpad",

	data() {
		return {
			buttons: [["7", "8", "9"], ["4", "5", "6"], ["1", "2", "3"], ["*", "0", "#"]]
		};
	},

	computed: {
		// Store value is used here beacuse CallDraggable.vue component ancestry too far to bubble up data
		...mapGetters("voice", ["digitsPressed"])
	},

	methods: {
		sendDigits(digit) {
			this.$store.commit("voice/setDigitsPressed", digit);
		}
	}

};
</script>

<style scoped>
.centered-input >>> input {
      text-align: center
}
</style>