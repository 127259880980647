import PhoneCall from "./phone-call";
import store from "../../store";

class InboundCall extends PhoneCall {
	constructor(callInstance) {
		super(callInstance);
		this.direction = "inbound";

		this.callDetails = {
			...(callInstance.customParameters ? Object.fromEntries(callInstance.customParameters) : {})
		};

		this.callDetails.phoneNumber = callInstance.parameters?.From;
		this.callDetails.callSid = callInstance.parameters?.CallSid;
		store.commit("voice/setCallDetails", this.callDetails);
	}

	static from(callInstance) {
		return new InboundCall(callInstance);
	}
}

export default InboundCall;
