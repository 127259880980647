import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import alerts from "./alerts";
import appointment from "./appointment";
import article from "./article";
import blocGrid from "./blocGrid";
import clinic from "./clinic";
import contact from "./contact";
import conversation from "./conversation";
import consultation from "./consultation";
import groups from "./groups";
import login from "./login";
import onboarding from "./onboarding";
import sip from "./sip";
import ui from "./ui";
import voice from "./voice";
import localeFilter from "./localeFilter";

Vue.use(Vuex);

const persistedState = createPersistedState({
	paths: [
		"blocGrid.activePostType",
		"clinic.clinicData",
		"contact.options",
		"consultation.isPatientSelected",
		"consultation.patient",
		"conversation.audioNotificationsEnabled",
		"conversation.desktopNotificationsEnabled",
		"conversation.browserNotificationsDisabled",
		"conversation.showNotificationsAlert",
		"conversation.signatureOption",
		"conversation.currentGroup",
		"sip",
		"login",
		"localeFilter",
		"ui"
	]
});

export default new Vuex.Store({
	modules: {
		alerts,
		appointment,
		article,
		blocGrid,
		clinic,
		consultation,
		contact,
		conversation,
		groups,
		login,
		localeFilter,
		onboarding,
		sip,
		ui,
		voice
	},
	plugins: [persistedState]
});
