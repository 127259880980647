import axios from "axios";
import { updateField, getField } from "vuex-map-fields";
import { Main } from "../main";
import { ISO_COUNTRY_FR } from "../common/const";
import moment from "moment";

const getDefaultState = () => ({
	clinicData: {},
	clinicServices: undefined,
	allServices: [],
	allTokens: []
});

export default {
	getters: {
		getField,
		clinicData: (state) => state.clinicData,
		clinicServices: (state) => state.clinicServices,
		allServices: (state) => state.allServices,
		isFrance: (state) => state.clinicLocation == ISO_COUNTRY_FR,
		country: (state) => state.clinicLocation,
		isSector: (state) => (sector) => state.clinicData.sector === sector,
		hasMultipleNumbers: (state) => state.clinicData.options?.twilio?.hasMultipleNumbers,
		hasWebsite: (state) => !!state.clinicData.url,
		webCallsEnabled: (state) => state.clinicData.options?.twilio?.webCallsEnabled
	},
	mutations: {
		updateField,
		setClinic: (state, payload) => (state.clinicData = payload),
		setClinicServices: (state, payload) => (state.clinicServices = payload),
		setAllServices: (state, payload) => (state.allServices = payload),
		resetState(state) {
			Object.assign(state, getDefaultState());
		},
		setAllTokens: (state, allTokens) => (state.allTokens = allTokens)
	},
	actions: {
		async getClinicData({ commit, rootGetters, state, getters, dispatch }, id) {
			try {
				const clinicId = id || rootGetters["login/profile"].clinic;

				if (!clinicId) return;

				const { data: clinic } = await axios.get(`organizations/${clinicId}`);
				const hiddenFields = ["timeZone", "calendarId", "clientId"];
				const filteredClinic = {};

				state.clinicName = clinic.name["fr-FR"];
				state.clinicLogo = clinic.name.logo;
				state.clinicLocation = clinic.country;
				state.clientId = clinic.clientId;
				dispatch("getClinicServices", clinic);

				// Remove fields from clinic data
				for (const prop in clinic) {
					if (prop in clinic && !hiddenFields.includes(prop)) {
						filteredClinic[prop] = clinic[prop];
					}
				}

				if (filteredClinic?.schedule?.vacation) {
					filteredClinic.schedule.vacation.start = moment
						.utc(filteredClinic.schedule.vacation.start)
						.format("YYYY-MM-DD");

					filteredClinic.schedule.vacation.end = moment
						.utc(filteredClinic.schedule.vacation.end)
						.format("YYYY-MM-DD");
				}

				filteredClinic.logo = `${filteredClinic.logo}?${Date.now()}`;

				commit("setClinic", filteredClinic);
			} catch (err) {
				commit("alerts/add", {
					type: "error",
					message: Main.$i18n.t("error.get-clinic-data"),
					timeout: true
				}, {
					root: true
				});
			}
		},

		async getClinicServices({ commit, getters }, clinic) {
			const { data } = await axios.get(`services?sector=${clinic.sector}`);

			const clinicServices = data.filter((service) => clinic.services.includes(service.id));

			commit("setClinicServices", clinicServices);
		},

		async getServicesBySector({ commit }, sector) {
			const { data } = await axios.get(`services?sector=${sector}`);

			commit("setAllServices", data);
		},

		async updateWebCalls({ state, rootGetters, commit, dispatch }, isEnabled) {
			try {
				const clinicId = rootGetters["login/profile"].clinic;

				if (!clinicId) return;

				await axios.put(`organizations/${clinicId}`, {
					options: {
						...state.clinicData.options,
						twilio: {
							...state.clinicData.options.twilio,
							webCallsEnabled: isEnabled
						}
					}
				});

				dispatch("getClinicData");
			} catch (err) {
				commit(
					"alerts/add", {
						type: "error",
						message: Main.$i18n.t("error.update-web-calls"),
						timeout: true
					}, {
						root: true
					}
				);
			}
		},

		async fetchTokens({ rootGetters, commit }) {
			try {
				let { data: allTokens } = await axios.get(
					`communication-templates/tokens?sector=${rootGetters["clinic/clinicData"].sector}`
				);

				allTokens = allTokens.filter(token=>{
					return token.value !== "[Lien clinique]";

				});
				const clinicData = rootGetters["clinic/clinicData"];

				allTokens = allTokens.filter((token) => {
					if (!clinicData.url) {
						return Main.$i18n.t(token.description["en-CA"]) !== Main.$i18n.t("link");
					}
					return true;
				});

				if (rootGetters["clinic/isFrance"]) {
					allTokens = allTokens.filter(
						(token) =>
							token.description["fr-FR"] !== "Lien du questionnaire médical" &&
							token.description["fr-FR"] !== "Lien du questionnaire covid"
					);
				}

				commit("setAllTokens", allTokens);
			} catch (err) {
				commit(
					"alerts/add",
					{
						type: "error",
						message: Main.$i18n.t("error.get-tokens"),
						timeout: true
					},
					{ root: true }
				);
			}
		}
	},
	namespaced: true,
	state: () => ({
		clinicData: undefined,
		clinicServices: undefined,
		allServices: [],
		clinicLogo: undefined,
		clinicName: undefined,
		clinicLocation: undefined,
		clientId: undefined,
		allTokens: []
	})
};
