<template>
	<v-list nav>
		<v-list-item
			v-for="(item, index) in items"
			v-show="item.disabled ? !item.disabled() : true"
			:key="index"
			link
			:to="item.route"
			@click="$emit(`click:${item.id}`)"
		>
			<v-list-item-icon class="mr-3">
				<v-badge
					:value="!index && notification"
					color="red"
					overlap
					dot
				>
					<v-icon color="white">
						{{ item.icon }}
					</v-icon>
				</v-badge>
			</v-list-item-icon>

			<v-list-item-content class="ml-2">
				<v-list-item-title>
					{{ $t(item.title, item.titleParams) }}
				</v-list-item-title>

				<v-list-item-subtitle v-if="item.subtitle">
					{{ item.subtitle }}
				</v-list-item-subtitle>
			</v-list-item-content>
		</v-list-item>
	</v-list>
</template>

<script>
export default {
	name: "NavigationList",

	props: {
		items: {
			type: Array,
			required: true
		},

		notification: {
			type: Boolean,
			default: false
		}
	}
};
</script>
