<template>
	<v-sheet width="100%">
		<span class="text-body-1 px-2">{{ $t("support-instruction") }}</span>

		<v-form
			v-model="validForm"
			class="text-center my-5"
		>
			<v-row class="px-5 flex-column">
				<v-row>
					<v-col
						cols="6"
						class="pr-11"
					>
						<v-text-field
							v-model="inputForm.name"
							class="mt-5"
							outlined
							dense
							:label="$t('last-name')"
							:rules="[rules.required(form.name, 'name')]"
							background-color="light-primary"
						/>
					</v-col>

					<v-col
						cols="6"
						class="pr-11"
					>
						<v-text-field
							v-model="inputForm.email"
							class="mt-5"
							outlined
							dense
							:label=" $t('email')"
							:rules="[rules.required(form.email, 'email'), rules.email]"
							background-color="light-primary"
						/>
					</v-col>
				</v-row>

				<v-row class="mt-n7">
					<v-col cols="6">
						<v-row no-gutters>
							<v-col>
								<v-text-field
									v-model="inputForm.url"
									class="mt-5"
									outlined
									dense
									:label="$t('url')"
									background-color="light-primary"
								/>
							</v-col>
							<v-tooltip bottom>
								<template #activator="{ on, attrs }">
									<v-icon
										color="primary"
										class="mb-1 ml-2"
										v-bind="attrs"
										v-on="on"
									>
										mdi-information-outline
									</v-icon>
								</template>
								<span>{{ $t("url-tooltip") }}</span>
							</v-tooltip>
						</v-row>
					</v-col>

					<v-col cols="6">
						<v-row no-gutters>
							<v-file-input
								v-model="imgFileInput"
								class="mt-5"
								outlined
								prepend-icon=""
								append-icon="mdi-paperclip"
								dense
								:label="$t('screenshot')"
								background-color="light-primary"
								accept="image/png, image/jpeg, image/svg+xml"
								clearable
							/>
							<v-tooltip bottom>
								<template #activator="{ on, attrs }">
									<v-icon
										color="primary"
										class="mb-1 ml-2"
										v-bind="attrs"
										v-on="on"
									>
										mdi-information-outline
									</v-icon>
								</template>
								<span>{{ $t("screenshot-tooltip") }}</span>
							</v-tooltip>
						</v-row>
					</v-col>
				</v-row>

				<v-row no-gutters class="mt-n2">
					<v-textarea
						v-model="inputForm.description"
						outlined
						class="mt-2"
						:hint="$t('problem-info')"
						:label="$t('problem-description')"
						:rules="[rules.required(form.description, 'description')]"
						background-color="light-primary"
					/>
				</v-row>
			</v-row>
		</v-form>
	</v-sheet>
</template>

<script>
import { RequiredValidator, EmailValidator } from "@/validators";
import { mapGetters } from "vuex";

export default {
	name: "SupportForm",

	props:{
		value : {
			type : Boolean,
			default : false
		},

		fullPath :  {
			type : String,
			default : undefined
		},

		form : {
			type : Object,
			default : undefined
		},

		supportDialog : {
			type : Boolean,
			default : false
		}
	},

	data() {
		return {
			validForm: false,
			imgFileInput: undefined,
			rules: {
				email: EmailValidator,
				required: RequiredValidator
			},
			inputForm: {}
		};
	},

	computed: {
		...mapGetters("login", ["profile"]),
		...mapGetters("clinic", ["isFrance"])
	},

	watch: {
		form(updatedForm) {
			this.inputForm = updatedForm;
		},

		validForm (isFormValid) {
			this.$emit("updateValidation", isFormValid);
		},

		imgFileInput(newImgFileInput) {
			if (newImgFileInput) {
				const reader = new FileReader();

				reader.readAsDataURL(this.imgFileInput);
				reader.onload = () => {
					this.inputForm.screenshot = reader.result;
				};
			}
		},

		supportDialog(isCloseDialog) {
			if (isCloseDialog) {
				this.imgFileInput = undefined;
				this.$emit("onDialogClose");
			}
		}
	},

	mounted() {
		this.inputForm = this.form;
	}
};
</script>