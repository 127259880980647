import { getField } from "vuex-map-fields";
import axios from "axios";

export default {
	getters: {
		getField
	},
	mutations: {
		async updateUser(state, sip = false) {
			try {
				// Get sip from store if false is passed.
				if (sip === false) {
					sip = state.associated;
					console.debug("Setting SIP to store value:", sip);
				}

				await axios.put(`/sips/v1/users/${sip ?? null}`);
				state.associated = sip;
				console.debug("Associated sip:", sip);
			} catch (err) {
				console.error("sip/updateUser:", err);
			}
		}
	},
	namespaced: true,
	state: () => ({
		associated: undefined
	})
};
