<template>
	<v-layout align-center justify-center column class="mt-3">
		<contact-avatar :size="80" :contact="patientInfo" :anonymous="anonymous" />

		<div v-if="loadingPatientInfo" class="mt-3">
			<v-progress-circular
				indeterminate
				color="secondary"
			/>
		</div>
		<span v-else class="mt-2 text-h6 font-weight-semibold truncate">
			{{ participantLabel }}
		</span>

		<span v-show="participantName || loadingPatientInfo" class="mt-1 text-body-1 font-weight-semibold">
			{{ participantPhoneNumber }}
		</span>

		<span v-show="isOnCall && !durationLoading" class="mt-5 text-h6 font-weight-semibold">
			{{ callDuration }}
		</span>
		<v-progress-circular
			v-show="durationLoading"
			class="mt-5"
			size="20"
			color="primary"
			indeterminate
		/>
	</v-layout>
</template>

<script>
import { phoneNumberFormater, fullName } from "@/common/utils";
import { mapGetters } from "vuex";
import { isEmpty } from "lodash";
import ContactAvatar from "../../contact/ContactAvatar.vue";
import { HttpStatusCode } from "axios";
import { ANONYMOUS_CALLERS } from "@/common/const";

export default {
	name: "CallInfo",

	components: {
		ContactAvatar
	},

	data() {
		return {
			patientInfo: undefined,
			loadingPatientInfo: false
		};
	},

	computed: {
		...mapGetters("voice", ["isOnCall", "activeCall", "callDetails"]),

		callDuration() {
			return this.activeCall?.duration;
		},

		participantLabel() {
			return isEmpty(this.participantName)
				? this.participantPhoneNumber
				: fullName(this.participantName);
		},

		participantName() {
			return this.patientInfo?.name;
		},

		participantPhoneNumber() {
			return phoneNumberFormater(this.callDetails?.callerName || this.callDetails?.phoneNumber);
		},

		loading() {
			return this.callDetails?.loadingPatientInfo;
		},

		durationLoading() {
			return this.activeCall?.duration === "00:00" &&
			this.activeCall?.direction === "outbound";
		},

		anonymous() {
			return ANONYMOUS_CALLERS.includes(this.callDetails?.phoneNumber);
		}
	},

	async mounted() {
		if (this.callDetails) {
			try {
				this.loadingPatientInfo = true;
				const res = await this.axios.get(`patients/${this.callDetails.phoneNumber}`);

				this.patientInfo = res?.data;
				console.debug("CALLDETAILS (patient data):", this.patientInfo);
			} catch (error) {
				// Ignore error if contact is not found
				if (error.response.status !== HttpStatusCode.NotFound) {
					this.$error(this.$t("error.get-patient"));
				}
			} finally {
				this.loadingPatientInfo = false;
			}
		} else {
			console.error("No call details found");
		}
	}
};
</script>

<style scoped>
.truncate {
	word-wrap: break-word;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	word-break: break-all;
}
</style>
