<template>
	<v-badge
		v-model="business"
		bordered
		bottom
		right
		:offset-x="size * 0.25"
		:offset-y="size * -0.07"
		class="badge-container"
	>
		<template #badge>
			<v-icon>mdi-domain</v-icon>
		</template>

		<v-sheet v-if="sentiment && !anonymous" class="position-absolute above rounded-circle">
			<sentiment-emoji :size="size / 4" :sentiment="sentiment" class="antialias" />
		</v-sheet>

		<v-avatar :color="color" :size="size">
			<span v-if="initials" class="white--text font-weight-bold" :style="{ fontSize: (size / 3) + 'px' }">
				{{ initials }}
			</span>

			<v-icon v-else color="white" :size="size * 0.75">
				{{ anonymous ? "mdi-incognito" : "mdi-account" }}
			</v-icon>
		</v-avatar>
	</v-badge>
</template>

<script>
import { initialsGenerator } from "@/common/utils";
import SentimentEmoji, { Sentiment } from "../conversations/contact-card/SentimentEmoji.vue";

export default {
	name: "ContactAvatar",
	components: { SentimentEmoji },

	props: {
		size: {
			type: Number,
			default: 50
		},

		contact: {
			type: Object,
			default: undefined
		},

		sentimentScore: {
			type: Number,
			default: undefined
		},

		anonymous: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		initials() {
			if (this.contact?.name?.first || this.contact?.name?.last) {
				return initialsGenerator(this.$options.filters.fullName(this.contact.name));
			}

			return undefined;
		},

		color() {
			return this.contact?.id ? "initials" : "avatar";
		},

		business() {
			return !!this.contact?.business;
		},

		sentiment() {
			if (this.sentimentScore >= 70) return Sentiment.Positive;
			if (this.sentimentScore <= 40) return Sentiment.Negative;

			return undefined;
		}
	}
};
</script>
<style>
	.badge-container .v-badge__wrapper{
		position: relative;
	}
</style>