import {
	CONNECTED,
	CONNECTION_FAILED,
	INITIALIZATION_FAILED,
	INITIALIZING,
	desktopNotification,
	playAudio,
	toggleState
} from "../comms";
import Thread, { THREAD_STATE_ACTIVE, THREAD_STATE_INACTIVE } from "./thread";

import { MessagingEvent } from "./messaging-event";
import ThreadList from "./thread-list";
import Vue from "vue";
import { fromEvent } from "rxjs";
import store from "../../store";

const threadList = new ThreadList();

let allThreads = [];

let eventSource;

/** @param {Thread} thread Updated thread instance */
const onMessageCreated = async (thread) => {
	const messageExists = store.getters["conversation/messages"]
		.findIndex(message => message.id == thread.lastMessage.id) != -1;

	if (
		thread.lastMessage.direction == "outbound" && messageExists
	) {
		return;
	}

	store.commit("conversation/addSingleMessage", thread.lastMessage);
	store.commit("conversation/setNewMessage", true);

	if (thread.state.current == THREAD_STATE_INACTIVE) {
		await thread.setState(THREAD_STATE_ACTIVE);
		store.dispatch("conversation/displayConversation");
	}

	if (thread.lastMessage.direction == "inbound") {
		playAudio();
		desktopNotification();
	}
};

/** @param {Thread} thread The target Thread instance */
const onMessageUpdated = async (thread) => {
	const { items: messages } = await thread.getMessages();

	store.getters["conversation/messages"].forEach(localMsg => {
		const incomingMessage =  messages.find(message => message.sid == localMsg.sid);

		if (localMsg?.updatedAt !== incomingMessage.updatedAt) {
			Object.assign(localMsg, incomingMessage);
		}
	});
};

/** @param {Thread} thread Newly created thread instance */
const onThreadCreated = (thread) => {
	store.commit("conversation/addConversation", thread);
	store.dispatch("conversation/displayConversation", thread.sid);
};

/** @param {Thread} thread Updated thread instance */
const onThreadUpdated = async (thread) => {
	const currentThread = store.getters["conversation/allConversations"]?.[thread.sid];

	if (
		!currentThread || currentThread.dateUpdated?.getTime() === thread.dateUpdated?.getTime()
	) {
		return;
	}

	if (
		thread.lastMessage
		&& currentThread.sid == store.getters["conversation/singleConversation"].sid
	) {
		// Message created
		if (currentThread.lastMessage?.sid != thread.lastMessage?.sid) {
			onMessageCreated(thread);
		} else {
			// Message updated
			onMessageUpdated(thread);
		}
	} else {
		if (currentThread.lastMessage?.sid != thread.lastMessage?.sid) {
			playAudio();
			desktopNotification();
		}
	}

	Object.assign(currentThread, thread);
};

/** @param {Thread} thread Removed thread instance */
const onThreadRemoved = (thread) => {
	store.commit("conversation/removeConversation", thread);
	store.dispatch("conversation/displayConversation");
};

const onError = (err) => {
	toggleState(CONNECTION_FAILED);
};

const registerWatcher = () => {
	eventSource = new MessagingEvent("messaging/v1/polling");

	eventSource.poll();

	fromEvent(eventSource, "create")
		.subscribe(({ detail }) => onThreadCreated(detail));

	fromEvent(eventSource, "update")
		.subscribe(({ detail }) => onThreadUpdated(detail));

	fromEvent(eventSource, "remove")
		.subscribe(({ detail }) => onThreadRemoved(detail));

	fromEvent(eventSource, "error")
		.subscribe(({ detail }) => onError(detail));
};

const fetchThreads = async () => {
	allThreads = (await threadList.findAll()).map(thread => {
		Vue.set(thread, "active", false);
		return thread;
	});

	store.commit("conversation/setAllConversation", allThreads);
	store.dispatch("conversation/displayConversation");
};

const bootstrap = async () => {
	try {
		toggleState(INITIALIZING);

		await fetchThreads();
		registerWatcher();

		toggleState(CONNECTED);
	} catch (err) {
		console.error(err);
		toggleState(INITIALIZATION_FAILED);
	}
};

const cleanup = () => {
	if (eventSource) eventSource.close();
};

const messaging = {
	bootstrap,
	threadList,
	cleanup
};

export default messaging;