<template>
	<modal
		v-model="showDialog"
		:width="$vuetify.breakpoint.xl ? '60%' : '70%'"
		:title="$t('contact-us')"
		type="question"
		:is-cta-disable="isCtaDisable"
		:cancel-text="$t('cancel')"
		:confirm-text="$t('send')"
		@cancel="closeDialog"
		@confirm="sendMessage"
	>
		<v-row no-gutters class="px-2">
			<support-form
				v-model="validForm"
				:form="form"
				:support-dialog="supportDialog"
				@updateValidation="updateValidation"
				@onDialogClose="supportDialog = false"
			/>
		</v-row>
	</modal>
</template>

<script>
import { mapGetters } from "vuex";
import SupportForm from "./SupportForm";
import Modal from "@/components/common/Modal";
import { fullName } from "@/common/utils";

export default {
	name: "SupportDialog",
	components: { SupportForm, Modal },

	props: {
		value: {
			type: Boolean,
			default: false
		},

		fullPath: {
			type: String,
			default: undefined
		}
	},

	data() {
		return {
			validForm: false,
			loading: false,
			form: {
				name: undefined,
				email: undefined,
				url: undefined,
				screenshot: undefined,
				description: undefined
			},
			supportDialog: false
		};
	},

	computed: {
		...mapGetters("login", ["profile"]),

		showDialog: {
			get() {
				return this.value;
			},

			set(value) {
				this.$emit("input", value);
			}
		},

		isCtaDisable() {
			return {
				cancel: this.loading,
				confirm: !this.validForm
			};
		}
	},

	watch: {
		fullPath(newPath) {
			this.form.url = newPath;
		}
	},

	async mounted() {
		this.form.name = fullName(this.profile.name);
		this.form.email = this.profile.email;
		this.form.url = this.fullPath;
	},

	methods: {
		async sendMessage() {
			this.loading = true;

			try {
				await this.axios.post("email/support", {
					name: this.form.name,
					email: this.form.email,
					pageLink: this.form.url,
					screenshotFile: this.form.screenshot,
					description: this.form.description
				});

				this.$success(this.$t("message-sent"));
				this.closeDialog();
			} catch (err) {
				this.$error(this.$t("error.send-support-request"));
			} finally {
				this.loading = false;
			}
		},

		closeDialog() {
			this.supportDialog = true;
			this.form = {
				...this.form,
				screenshot: undefined,
				description: undefined
			};
			this.$emit("closeDialog");
		},

		updateValidation(isFormValid) {
			this.validForm = isFormValid;
		}
	}
};
</script>

<style scoped>
.content-height {
	height: 45vh;
	overflow-y: auto !important;
}
</style>
