<template>
	<modal
		v-model="showDialog"
		width="700"
		:title="$t('logout')"
		type="question"
		:is-cta-disable="isCtaDisable"
		:cancel-text="$t('cancel')"
		:confirm-text="$t('logout')"
		@cancel="showDialog = false"
		@confirm="logoutUser"
	>
		<v-card-text class="py-0 px-4 mb-4">
			<transition name="fade">
				<span
					v-show="!loading"
					transition="fade-transition"
					class="text-body-1"
				>
					{{ $t("logout-text1") }}
				</span>
			</transition>

			<transition name="fade">
				<span
					v-show="loading"
					transition="fade-transition"
					class="text-body-1"
				>
					{{ $t("logout-text2") }}
				</span>
			</transition>
		</v-card-text>
	</modal>
</template>

<script>
import { mapActions } from "vuex";
import Modal from "../common/Modal.vue";

export default {
	name: "LogoutDialog",
	components: { Modal },

	props: {
		value: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			loading: false
		};
	},

	computed: {
		showDialog: {
			get() {
				return this.value;
			},

			set(value) {
				this.$emit("input", value);
			}
		},

		isCtaDisable() {
			return {
				cancel: this.loading,
				confirm: this.loading
			};
		}
	},

	methods: {
		...mapActions("login", ["logout"]),

		async logoutUser() {
			this.loading = true;
			try {
				this.$store.commit("login/setLogoutTrigger", true);
				this.$store.commit("onboarding/resetState");
				await this.logout();
			}
			finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style>
.fade-enter-active {
	transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-active {
	opacity: 0;
}
</style>
