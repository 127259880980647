import PhoneCall from "./phone-call";
import store from "../../store";

class OutboundCall extends PhoneCall {
	constructor(callInstance) {
		super(callInstance);
		this.direction = "outbound";

		this.callDetails = {
			...(callInstance.customParameters ? Object.fromEntries(callInstance.customParameters) : {})
		};

		this.callDetails.phoneNumber = callInstance.customParameters.get("To");
		store.commit("voice/setCallDetails", this.callDetails);
	}

	start = async () => {
		const checkCallStatusInterval = setInterval(() => {
			const status = this.callInstance.status();

			// IVR with twilio number won't ring, the call status is "open" once the call started.
			if (status === "ringing" || status === "open") {
				clearInterval(checkCallStatusInterval);
				store.commit("voice/setIsOnCall", true);
			} else if (status === "closed") {
				clearInterval(checkCallStatusInterval);
				return;
			}
		}, 1000);
	};

	static from(callInstance) {
		return new OutboundCall(callInstance);
	}
}

export default OutboundCall;
