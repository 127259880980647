import axios from "axios";
import store from "../../store";
import Thread from "./thread";

const POLLING_INTERVAL = 2500;
const MAX_ATTEMPTS = 5;

let watcherId;

export class MessagingEvent extends EventTarget {
	static #instance = null;

	constructor(url) {
		if (MessagingEvent.#instance) return MessagingEvent.#instance;

		super();
		MessagingEvent.#instance = this;
		this.pollingUrl = url;
	}

	close() {
		if (watcherId) clearInterval(watcherId);
	}

	/**
	 * Use it to explicitly trigger thread creation upon user actions
	 * @param {*} thread The newly created thread object instance
	 */
	onThreadCreated(thread) {
		this.dispatchEvent(new CustomEvent("create", {
			detail: new Thread(thread)
		}));
	}

	/**
	 * Use it to explicitly trigger thread updates upon user actions
	 * @param {*} thread The updated thread object instance
	 */
	onThreadUpdated(thread) {
		this.dispatchEvent(new CustomEvent("update", {
			detail: new Thread(thread)
		}));
	}

	poll() {
		if (watcherId) clearInterval(watcherId);

		let attempts = 0;

		watcherId = setInterval(async () => {
			try {
				const { data: threads } = await axios.get(this.pollingUrl);

				attempts = 0;
				this._processBatch(threads);
			} catch (err) {
				attempts++;

				if (attempts >= MAX_ATTEMPTS) {
					this.dispatchEvent(new CustomEvent("error", {
						detail: "Max attempts exceeded."
					}));

					this.close();
				}
			}
		}, POLLING_INTERVAL);
	}

	/** @private */
	_processBatch(threads) {
		// Handle thread creation/removal
		if (
			Object.keys(store.getters["conversation/allConversations"])?.length == threads.length
		) {
			return this._processUpdated(threads);
		}

		this._processRemoved(threads);
		this._processCreated(threads);
	}

	/** @private */
	_processRemoved(threads) {
		const removed = Object.values(store.getters["conversation/allConversations"])?.filter(thread => {
			return threads.findIndex(convo => convo.sid == thread.sid) == -1;
		});

		removed.length && removed.forEach(thread => {
			this.dispatchEvent(new CustomEvent("remove", {
				detail: thread
			}));
		});
	}

	/** @private */
	_processCreated(threads) {
		const added = threads.filter(thread => {
			return Object.keys(store.getters["conversation/allConversations"])
				.findIndex(sid => sid === thread.sid) == -1;
		});

		added.length && added.forEach(thread => {
			this.dispatchEvent(new CustomEvent("create", {
				detail: new Thread(thread)
			}));
		});
	}

	/** @private */
	_processUpdated(threads) {
		const updated = threads.filter(updatedThread => {
			const currentThread = Object.keys(store.getters["conversation/allConversations"])
				.find(sid => sid === updatedThread.sid);

			return currentThread?.updatedAt != updatedThread.updatedAt;
		});

		updated.length && updated.forEach(thread => {
			this.dispatchEvent(new CustomEvent("update", {
				detail: new Thread(thread)
			}));
		});
	}
}