import { MAX_UPLOAD_SIZE } from "@/common/const";
import { Main } from "../main";

export function EmailValidator(v) {
	return !v || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || Main.$i18n.t("error.validator.email-invalid");
}

export function IDValidator(v) {
	return !v || !/-/gm.test(v) || Main.$i18n.t("error.validator.id-invalid");
}

export function PhoneValidator(phoneInputObject) {
	if (phoneInputObject.number && !phoneInputObject.number.input) {
		return true;
	}
	return phoneInputObject.valid || Main.$i18n.t("error.validator.phone-invalid");
}

export function PasswordValidator(v) {
	if (v?.length < 8) {
		return Main.$i18n.t("error.validator.password-length");
	} else if (!/(?=.*[a-z])/.test(v)) {
		return Main.$i18n.t("error.validator.lowercase");
	} else if (!/(?=.*?[A-Z])/.test(v)) {
		return Main.$i18n.t("error.validator.uppercase");
	} else if (!/(?=.*?[0-9])/.test(v)) {
		return Main.$i18n.t("error.validator.digit");
	} else if (!/(?=.*?[#_~+='".,:;|/<>)(?!@$%^&*-/£\[\]])/.test(v)) {
		return Main.$i18n.t("error.validator.special-character");
	} else {
		return true;
	};
}

export function ConfirmPasswordValidator(password, confirmPassword) {
	return password === confirmPassword ? true : Main.$i18n.t("error.validator.password-does-not-match");
}

export function RequiredValidator(v, name) {
	if (typeof v === "number") return v > -1 || Main.$i18n.t(`error.validator.required.${name}`);
	else return v?.length > 0 || Main.$i18n.t(`error.validator.required.${name || "default"}`);
}

export function MaxLengthValidator(v, maxLength) {
	return v?.length < maxLength || Main.$i18n.t("error.validator.maxLength", { maxLength });
}

export function UrlValidator(v) {
	return !v || /^((ftp|http|https):\/\/)?(www\.)?([A-z]+)\.([A-z]{2,})/.test(v) || Main.$i18n.t("error.validator.url-invalid");
}

export function NumberValidator(v) {
	return !v || /^[0-9]*$/.test(v) || Main.$i18n.t("error.validator.number-invalid");
}

export function FileSizeValidator(file, mb) {
	console.debug("FILE:", file);
	return !file || file.size < mb * 1024 * 1024 || Main.$i18n.t("error.validator.maxUploadSize", { mb });
}