import * as Voice from "@twilio/voice-sdk";

import InboundCall from "./inbound-call";
import OutboundCall from "./outbound-call";
import axios from "axios";
import i18n from "../../i18n";
import store from "../../store";
import { validatePhoneForE164 } from "../../common/utils";

var VoiceDevice = null;

const getAccessToken = async () => {
	console.debug("[CALL] getAccessToken");

	const { data: voiceToken } = await axios.post("/voice/v1/tokens", {
		identity: store.getters["login/profile"]?.id
	});

	return voiceToken.jwt;
};

const onInboundCall = async (call) => {
	console.debug("[CALL] onInboundCall");

	if (VoiceDevice.isBusy || store.getters["voice/hasIncomingCall"]) {
		console.debug("[CALL] call.reject()");
		call?.reject();
		return ;
	}

	const inboundCall = InboundCall.from(call);

	store.commit("voice/setInboundCall", inboundCall);

	console.debug("[CALL] setting up events");

	// Triggered when the user cancelled the call without answering it
	inboundCall.callInstance.on("reject", async () => {
		console.debug("[CALL] disconnectCall (reject)");
		store.commit("voice/disconnectCall");
	});

	// Triggered any time a call is closed
	inboundCall.callInstance.on("disconnect", () => {
		console.debug("[CALL] disconnectCall (disconnect)");
		store.commit("voice/disconnectCall");
	});

	// Triggered when an call is canceled by the caller before it is accepted
	inboundCall.callInstance.on("cancel", async () => {
		console.debug("[CALL] disconnectCall (cancel)");
		store.commit("voice/disconnectCall");
	});

	// Triggered when an incoming connection accepted
	inboundCall.callInstance.on("accept", () => {
		console.debug("[CALL] acceptCall");
		inboundCall.countDuration();
		store.commit("voice/acceptCall");
	});
};

const outboundCall = async (phoneNumber, hasPatient) => {
	console.debug("[CALL] outboundCall");

	if (!VoiceDevice) {
		console.error("VoiceDevice not yet initialized");
		return;
	}

	if (VoiceDevice.isBusy || store.getters["voice/hasIncomingCall"]) {
		console.debug("[CALL] VoiceDevice.isBusy");
		return;
	}

	if (!validatePhoneForE164(phoneNumber)) {
		console.debug("[CALL] INVALID PHONE");

		store.commit("alerts/add", {
			type: "error",
			message: i18n.t("error.phone-invalid"),
			timeout: false
		}, { root: true });

		return;
	}

	const outboundCallInstance = await VoiceDevice.connect({
		params: {
			To: phoneNumber,
			From: store.getters["conversation/currentParticipant"]?.phoneNumber
		}
	});

	const outboundCall = OutboundCall.from(outboundCallInstance);

	store.commit("voice/setOutboundCall", outboundCall);

	outboundCall.callInstance.on("accept", () => {
		outboundCall.countDuration();
		store.commit("voice/acceptCall");
	});

	outboundCall.callInstance.on("reject", () => {
		store.commit("voice/disconnectCall");
	});

	outboundCall.callInstance.on("disconnect", () => {
		store.commit("voice/disconnectCall");
	});

	outboundCall.callInstance.on("error", (err) => {
		if (err?.name === "ConnectionError") {
			store.commit("alerts/add", {
				type: "error",
				message: i18n.t("error.call-error"),
				timeout: true
			}, { root: true });
		}
	});

	await outboundCall.start(hasPatient);
	console.debug("[CALL] OUTBOUND CALL SETUP COMPLETE");
};

const bootstrap = async () => {
	console.debug("[CALL] bootstrap");
	await cleanup();
	initVoiceDevice();
};

async function initVoiceDevice() {
	console.debug("[CALL] initVoiceDevice");

	if (VoiceDevice) {
		console.warn("VoiceDevice already initialized");
		return;
	}

	const accessToken = await getAccessToken();

	VoiceDevice = new Voice.Device(accessToken, {
		allowIncomingWhileBusy: false
	});

	console.debug(`[CALL] VoiceDevice: ${VoiceDevice}`);

	VoiceDevice.on("tokenWillExpire", async () => {
		console.debug("[CALL] tokenWillExpire");
		const newToken = await getAccessToken();

		VoiceDevice.updateToken(newToken);
	});

	VoiceDevice.on("registered", () => {
		console.debug("[CALL] registered");
		store.commit("voice/setReady", true);
	});

	VoiceDevice.on("unregistered", () => {
		console.debug("[CALL] unregistered");
		store.commit("voice/setReady", false);
	});

	VoiceDevice.on("incoming", onInboundCall);

	VoiceDevice.on("error", (err) => {
		console.debug("[CALL] error");
		console.error(err);
	});

	VoiceDevice.register();
	console.debug("[CALL] VOICE DEVICE REGISTERED");
}

const cleanup = async () => {
	console.debug("[CALL] cleanup");

	store.commit("voice/disableMicrophone");
	store.commit("voice/reset");

	VoiceDevice?.destroy();
	VoiceDevice = null;
};

const useVoice = {
	bootstrap,
	outboundCall,
	cleanup
};

export default useVoice;
